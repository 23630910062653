import { useQuery } from "@apollo/client";
import { createContext, useContext, useReducer } from "react";
import { _getStudentMyProfile, _me } from "../gql/auth";
import {
  IAuthContext,
  IGetStudentMyProfile,
  IInfoContext,
  IMeRes,
  IUser,
} from "../types/types";

const _auth: IAuthContext = {
  auth: false,
  init: false,
  reminder: true,
  _id: "",
  accessToken: "",
  fname: "",
  lname: "",
  email: "",
  role: "",
  status: "",
  created_at: 0,
  updated_at: 0,
  profile: null,
  login: () => {},
  logout: () => {},
  me: () => {},
  setReminder: () => {},
};
export const AuthContext = createContext<IAuthContext>(_auth);

const reducer = (state: IInfoContext, action: any) => {
  switch (action.type) {
    case "set":
      return { ...action.payload, init: true };
    case "reset":
      return { ...action.payload, init: true };
    case "reminder":
      return { ...state, reminder: action.payload };
  }
  return state;
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [_state, dispatch] = useReducer(reducer, _auth);
  const { refetch } = useQuery<IGetStudentMyProfile>(_getStudentMyProfile, {
    skip: true,
  });

  AuthContext.displayName = "Auth Context";
  const { refetch: me } = useQuery<IMeRes>(_me, {
    async onCompleted(data) {
      const profile = await refetch();
      const token = localStorage.getItem(
        process.env.REACT_APP_LOCAL_TOKEN || ""
      );
      const payload: any = {
        ...data.me,
        auth: true,
        accessToken: token,
        profile: profile.data.getStudentMyProfile.profile,
      };
      await dispatch({ type: "set", payload: payload });
    },
    async onError(e) {
      localStorage.removeItem(process.env.REACT_APP_LOCAL_TOKEN || "");
      await dispatch({ type: "reset", payload: _auth });
    },
  });

  const login = async (user: IUser) => {
    localStorage.setItem(
      process.env.REACT_APP_LOCAL_TOKEN || "",
      user.accessToken
    );
    // await dispatch({ type: "set", payload: { ...user, auth: true } });
    await me();
  };
  const logout = async () => {
    localStorage.removeItem(process.env.REACT_APP_LOCAL_TOKEN || "");
    await dispatch({ type: "reset", payload: _auth });
  };

  // Auth Reminder Popup

  const setReminder = async (reminder: boolean) => {
    await dispatch({ type: "reminder", payload: reminder });
  };

  return (
    <AuthContext.Provider value={{ ..._state, login, logout, me, setReminder }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const auth = useContext(AuthContext);
  return auth;
}

export default useAuth;
