import { ToastContainer } from "material-react-toastify";
import React, { useEffect } from "react";
import SuspenseScreen from "./router/suspense-screen";
import Router from "./router";
import useInfo from "./utils/context/info-context";
import useAuth from "./utils/context/auth-context";
import useCart from "./utils/context/cart-context";
import SignInReminderPopup from "./components/signin-reminder";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const { init } = useInfo();
  // const [authInit, setAuthInit] = useState<boolean>(false);
  const { me, init: _auth_init, auth, reminder } = useAuth();
  const { getCart } = useCart();
  const location = useLocation();

  const isAuthPages =
    (location.pathname as string) === "/auth/register" ||
    (location.pathname as string) === "/auth/login" ||
    (location.pathname as string) === "/auth/forget-password";

  useEffect(() => {
    i();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    AOS.init();
  }, []);



  const i = async () => {
    await me();
    await getCart();
    // setAuthInit(true);
  };

  return (
    <div className="w-full overflow-x-hidden">
      {init && _auth_init ? <Router /> : <SuspenseScreen />}
      {!auth && init && _auth_init && reminder && !isAuthPages ? (
        <SignInReminderPopup />
      ) : null}
      <ToastContainer position="top-right" className="mt-32  md:mt-16" />
    </div>
  );
}

export default App;
