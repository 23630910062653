import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props: { children: any; }) => {
    const location = useLocation();
    useEffect(() => {
        // scroll to elements with class name top-desti
        const topElements = document.getElementsByClassName("top-desti");
        if (topElements.length > 0) {
            topElements[0].scrollIntoView({ behavior: "smooth" })
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;