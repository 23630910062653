import _ from "lodash";
import { createContext, useContext, useReducer } from "react";
import { ICart, ICartContext, ICartItem } from "../types/types";

export const CartContext = createContext<ICartContext>({
  cart: {},
  addToCart: () => {},
  removeCart: () => {},
  getCart: () => {},
  clearCart: () => {},
});

const reducer = (state: ICart, action: any) => {
  switch (action.type) {
    case "set":
      return action.payload;
    case "reset":
      return {};
  }
  return state;
};

export const CartProvider = ({ children }: { children: JSX.Element }) => {
  const [_state, dispatch] = useReducer(reducer, {});
  CartContext.displayName = "Cart Context";

  const addToCart = async (item: ICartItem) => {
    let data = _.clone(_state);
    data[item.id] = item;
    await localStorage.setItem(process.env.REACT_APP_LOCAL_TOKEN + "_cart" || "", JSON.stringify(data));
    await dispatch({ type: "set", payload: data });
  };
  const removeCart = async (id: string) => {
    let data = _.clone(_state);
    data[id] && delete data[id];
    await localStorage.setItem(process.env.REACT_APP_LOCAL_TOKEN + "_cart" || "", JSON.stringify(data));
    await dispatch({ type: "set", payload: data });
  };
  const clearCart = async () => {
    await localStorage.setItem(process.env.REACT_APP_LOCAL_TOKEN + "_cart" || "", JSON.stringify({}));
    await dispatch({ type: "set", payload: {} });
  };
  const getCart = async () => {
    try {
      const _data = await localStorage.getItem(process.env.REACT_APP_LOCAL_TOKEN + "_cart" || "");
      if (_data) {
        const data = JSON.parse(_data);
        await dispatch({ type: "set", payload: data });
      } else {
        await dispatch({ type: "reset", payload: {} });
      }
    } catch (error) {}
  };

  return <CartContext.Provider value={{ cart: _state, addToCart, removeCart, getCart, clearCart }}>{children}</CartContext.Provider>;
};

function useCart() {
  const cart = useContext(CartContext);

  return cart;
}

export default useCart;
