import { Outlet } from "react-router-dom";
import Footer from "./footer";
import TopNav from "./top-nav";
import BottomBar from "./bottom-bar";

function HomeLayout() {
  return (
    <div className="relative w-full h-screen bg-white overflow-y-auto style-scroll">
      <BottomBar />
      <div className="relative top-0 left-0 top-desti" />
      <TopNav />
      <div className="min-h-screen flex flex-col pt-20 ">
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomeLayout;
