import React from "react";
import { Link } from "react-router-dom";
import LOGO from "../assets/logo_desc.png";

function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <div>
      {/* <div>
        <main className="grid pb-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-6 place-items-start gap-4">
          <div className="md:col-span-3 pb-4">
            <img src={LOGO} alt="" className="max-w-xs md:max-w-md	" />
            <p className="mt-4 max-w-md text-dark">Welcome to the largest, precised ONLINE MCQ HUB offred to the Sri Lankan Schools student community</p>
          </div>
          <div className="grid grid-cols-1 gap-2 text-center w-full md:text-left">
            <h6 className="text-primary">Sitemap</h6>
            <Link to={"/"}>
              <p className="text-dark">Home</p>
            </Link>
            <Link to={"/courses"}>
              <p className="text-dark">Courses</p>
            </Link>
            <Link to={"/institutes"}>
              <p className="text-dark">Institutes</p>
            </Link>
            <Link to={"/tutors"}>
              <p className="text-dark">Tutors</p>
            </Link>
            <Link to={"/about-us"}>
              <p className="text-dark">About us</p>
            </Link>
          </div>
          <div className="grid grid-cols-1 gap-2 text-center w-full md:text-left">
            <h6 className="text-primary">Useful Links</h6>
            <Link to={"/"}>
              <p className="text-dark"> What We Do</p>
            </Link>
            <Link to={"/"}>
              <p className="text-dark">Our Projects</p>
            </Link>
            <Link to={"/"}>
              <p className="text-dark">FAQ</p>
            </Link>
            <Link to={"/"}>
              <p className="text-dark">Blog</p>
            </Link>
            <Link to={"/"}>
              <p className="text-dark">Support Team</p>
            </Link>
          </div>

          <div className="mx-auto">
            <div className="grid grid-cols-1 gap-2 place-items-left w-full md:text-left">
              <h6 className="text-primary text-center md:text-left">Contact Us</h6>
              <a href="tel:+94712345678" className="flex items-center justify-start">
                <svg
                  className="bg-white text-accent w-8 h-8 p-2 mr-4 rounded-full shadow-lg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2952 11.9448L12.114 10.4209C11.8287 10.2219 11.4953 10.117 11.1496 10.117C10.5999 10.117 10.0838 10.3867 9.76951 10.8378L9.26317 11.5634C8.41456 10.9943 7.46763 10.1996 6.56494 9.29704C5.6624 8.39451 4.86788 7.44759 4.2989 6.59897L5.02422 6.09263C5.39394 5.83519 5.64087 5.44952 5.71928 5.00696C5.79754 4.56474 5.6989 4.11791 5.44113 3.74802L3.9175 1.56681C3.5989 1.11111 3.08484 0.838867 2.54217 0.838867C2.3541 0.838867 2.16965 0.872075 1.99407 0.936847C1.79466 1.0105 1.60889 1.10091 1.42674 1.21533L1.12557 1.4274C1.05027 1.48593 0.98024 1.55004 0.913167 1.61712C0.545741 1.98438 0.285009 2.44929 0.137875 2.99903C-0.489953 5.35252 1.0654 8.91137 4.00809 11.8541C6.47929 14.3253 9.44811 15.8604 11.7557 15.8607H11.7559C12.1511 15.8607 12.5236 15.8147 12.8633 15.7239C13.413 15.577 13.8779 15.3162 14.2455 14.9486C14.3123 14.8819 14.376 14.8119 14.4444 14.7234L14.6567 14.4206C14.7604 14.2549 14.8507 14.0691 14.9253 13.8682C15.1819 13.1745 14.9228 12.3834 14.2952 11.9448V11.9448Z"
                    fill="currentColor"
                  />
                </svg>

                <p className="text-dark">+94 71 234 5678</p>
              </a>
              <a href="mailto:info@aplus.lk" className="flex items-center justify-start">
                <svg
                  className="bg-white text-accent w-8 h-8 p-2 mr-4 rounded-full shadow-lg"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3912 8.94923C9.97698 9.22535 9.49588 9.37132 8.99985 9.37132C8.50385 9.37132 8.02275 9.22535 7.60856 8.94923L0.786628 4.40113C0.748779 4.3759 0.711906 4.3496 0.675781 4.32254V11.7751C0.675781 12.6295 1.36918 13.3077 2.20835 13.3077H15.7913C16.6458 13.3077 17.3239 12.6143 17.3239 11.7751V4.32251C17.2877 4.34963 17.2508 4.376 17.2128 4.40126L10.3912 8.94923Z"
                    fill="currentColor"
                  />
                  <path
                    d="M1.32772 3.58945L8.14966 8.13758C8.4079 8.30975 8.70386 8.39582 8.99982 8.39582C9.29581 8.39582 9.5918 8.30972 9.85004 8.13758L16.672 3.58945C17.0802 3.31746 17.3239 2.86223 17.3239 2.37092C17.3239 1.52612 16.6366 0.838867 15.7919 0.838867H2.20783C1.36307 0.8389 0.675781 1.52616 0.675781 2.37173C0.675781 2.86223 0.91952 3.31746 1.32772 3.58945V3.58945Z"
                    fill="currentColor"
                  />
                </svg>

                <p className="text-dark">info@aplus.lk</p>
              </a>
              <div className="flex items-center justify-start">
                <svg
                  className="bg-white text-accent w-8 h-8 p-2 mr-4 rounded-full shadow-lg"
                  width="12"
                  height="17"
                  viewBox="0 0 12 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.92145 8.53871C6.90282 8.53871 7.69838 7.74315 7.69838 6.76179C7.69838 5.78042 6.90282 4.98486 5.92145 4.98486C4.94009 4.98486 4.14453 5.78042 4.14453 6.76179C4.14453 7.74315 4.94009 8.53871 5.92145 8.53871Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.6489 16.1718C5.73509 16.2163 5.82879 16.2389 5.92308 16.2389C6.01736 16.2389 6.11107 16.2163 6.19725 16.1718C6.42746 16.0515 11.8462 13.1507 11.8462 6.76194C11.8462 3.49615 9.18887 0.838867 5.92308 0.838867C2.65729 0.838867 0 3.49615 0 6.76194C5.92308e-08 13.1507 5.41869 16.0515 5.6489 16.1718V16.1718ZM5.9225 3.80041C7.55539 3.80041 8.88404 5.12905 8.88404 6.76194C8.88404 8.39484 7.55539 9.72348 5.9225 9.72348C4.2896 9.72348 2.96096 8.39484 2.96096 6.76194C2.96096 5.12905 4.2896 3.80041 5.9225 3.80041Z"
                    fill="currentColor"
                  />
                </svg>

                <p className="text-dark">Kandy, Srilanka</p>
              </div>
            </div>
          </div>
        </main>
      </div> */}
      <div className="bg-dark">
        <main className=" flex items-center justify-center flex-wrap pt-4 pb-16 sm:py-8">
          <p className="text-white text-center">Copyright {currentYear} aPlus Education. All right reserved.</p>
          {/* <div className="flex-grow h-4 w-screen sm:w-4"></div>
          <Link to={"/about-us/refund-policy"}>
            <p className="text-white mx-4">Refund Policy</p>
          </Link>
          <Link to={"/about-us/privacy-policy"}>
            <p className="text-white">Privacy Policy</p>
          </Link> */}


        </main>
      </div>
    </div>
  );
}

export default Footer;
